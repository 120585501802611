import gql from "graphql-tag";

//GET year list
export const YEAR_QUERY = gql`
{
  yearList {
    title
    value
  }
  
}`;

//GET MakerList
export const GET_MAKERLIST_QUERY = gql`
      query add($year: String!) {
        
            makeList(year: $year) {
              title
              value
            }
        
      }`;

//GET ModelList
export const GET_MODELLIST_QUERY = gql`
      query add($make: String!) {
          modelList(make: $make) {
            title
            value
          }
      }`;


//GET user by id
export const GET_USERBYID_QUERY = gql`
  query add($_id: String!) {
    getById(_id: $_id) {
      step1{
        vehicleInfo {
          year
          maker
          model
          vin
          mileage
          zipCode
        },
        vehicleFeature {
          isRideshare
          is4x4
          isDiesel
          isTurboCharged
          isSuperCharged
        }
      },
    	step2 {
    	  firstName
    	  lastName
    	  email
    	  phone
      },
    step3 {
      question {
        milesPerMonthMin
        milesPerMonthMax
        keepVehicle
        payAtClaimTime
        levelOfCoverage
      },
      quote {
        monthlyPayment
        covered
        milesPerMonth
        keepVehicle
        deductible
      },
      userId
      vehicleId
    },
    step4 {
      firstName
      lastName
      email
      phone
      address
      state
      city
    }
    }
  }`;

//YOUR INFO//
//POST your info
export const POST_YOURINFO_MUTATION = gql`
      mutation add($record: CreateOneusersInput!) {
        
            step2(record: $record) {
              recordId
              vehicleId
              userId
             
          }
        
      }
      `;
//Update Your  info
export const UPDATE_YOURINFO_MUTATION = gql`
      mutation add($record: UpdateByIdusersInput!) {
        step {
            updatestep2(record: $record) {
             recordId
             record {
                firstName
                lastName
                email
                phone
             }
          }
        }
      }
      `;

//Customize Plan//
export const UPDATE_VEHICAL_MUTATION = gql`
      mutation add($record: UpdateByIdvehicleInfosInput!) {
        vehicleInfos {
            updateById(record: $record) {
                recordId
                record {
                    userId
                } 
        }
    }
}`;

export const POST_CUSTOMIZEPLAN_MUTATION = gql`
      mutation add($record: UpdateByIdvehicleInfosInput!) {
        step {
            step3(record: $record) {
             recordId
             record {
                question {
                    milesPerMonthMin
                    milesPerMonthMax
                    keepVehicle
                    payAtClaimTime
                    levelOfCoverage
                }
                quote {
                    monthlyPayment
                    covered
                    milesPerMonth
                    keepVehicle
                    deductible
                }
                userId
            }
        }
    }
}`;

// Create Account //
export const CREATE_ACCOUNT_MUTATION = gql`
      mutation add($record: UpdateByIdusersInput!) {
        step {
            step4(record: $record) {
             recordId
             record {
                firstName
                lastName
                email
                phone
                address
                state
                city
            }
        }
    }
}`;

// Secure Payment //
export const CREATE_PAYMENT_MUTATION = gql`
      mutation add($record: CreateOneuserscardsInput!) {
        step {
            step5(record: $record) {
             recordId
             record {
                tokenId
                userId
                cardId
                expMonth
                expYear
                last4
            }
        }
    }
}`;

//Update Profile
export const UPDATE_PROFILE_MUTATION = gql`
      mutation add($record: ProfileUpdateByIdusersInput!) {
        myprofile {
          updateprofile(record: $record) {
             recordId  
             record {
                firstName
                lastName
                email
                phone
                address
                state
                city
            }
        }
    }
}`;

//Me
export const ME_MUTATION = gql`
mutation {
  auth {
    me {
        firstName
        lastName
        email
        phone
        address
        state
        city
        level
        emailVerified
        token
        tokenExpiretimeAt
        activationCode
        isActive
        accessToken
        message
        vehicleId
        userId
  }
}
}`;

//Login
export const POST_LOGIN_MUTATION = gql`
      mutation add($record: LoginInput!) {
        auth {
            login(record: $record) {
              user {
                firstName
                lastName
                email
                password
                phone
                address
                state
                city
                level
                emailVerified
                token
                tokenExpiretimeAt
                activationCode
                isDeleted
                isActive
                updatedAt
                createdAt
                accessToken
                message
                vehicleId
                userId
              } 
              accessToken
          }
        }
      }
      `;


//Forgotpassword
export const FORGOT_PASSWORD_MUTATION = gql`
      mutation add($record: ForgotpasswordInput!) {
        auth {
          forgotpassword(record: $record) {
              email
          }
        }
      }
      `;

//Updatepassword
export const RESET_PASSWORD_MUTATION = gql`
      mutation add($record: UpdatepasswordInput!) {
        auth {
          updatepassword(record: $record) {
              email
          }
        }
      }
      `;

//ChangePassword
export const CHANGE_PASSWORD_MUTATION = gql`
      mutation add($record: ChangepasswordInput!) {
        myprofile {
          changepassword(record: $record) {
              email
          }
        }
      }
      `;

//addSubscription
export const ADD_EMAIL_SUBSCRIPTION_MUTATION = gql`
      mutation add($record: CreateOnenewsletterleadInput!) {
        newsletterlead  {
          addSubscription(record: $record) {
             recordId  
             record {
                email
            }
        }
    }
}`;

//Contact US
export const CONTACT_US_MUTATION = gql`
      mutation add($record: CreateOnecontactusleadInput!) {
        contactuslead {
          addNote(record: $record) {
             recordId  
             record {
                name
                email
                message
            }
        }
    }
}`;