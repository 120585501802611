import '../assets/css/login.css';

import Formsy from 'formsy-react';
import { navigate } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ButtonLoader from '../components/buttonLoader';
import { APPLLOCLIENT } from '../components/constant';
import ErrrorHandel from '../components/ErrrorHandel';
import MyInput from '../components/MyInput';
import { CHANGE_PASSWORD_MUTATION } from '../components/Queryconstant';
import close from '../images/close-icon.svg';
import bottomLine from '../images/login-line.svg';
import loginImg from '../images/loginBg.png';
import Logo from '../images/logo-white.png';
import * as actionCreators from '../store/actions';

class ChangePasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            successMsg: '',
        };
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = (model) => {
        const { showButtonLoader, hideButtonLoader } = this.props;
        showButtonLoader()
        APPLLOCLIENT
            .mutate({
                mutation: CHANGE_PASSWORD_MUTATION,
                variables: {
                    record: model
                }
            })
            .then(res => {
                hideButtonLoader();
                if (res.data && res.data.myprofile && res.data.myprofile.changepassword && res.data.myprofile.changepassword.email) {
                    this.setState({ successMsg: 'Password Change successfully' })
                    setTimeout(() => {
                        this.setState({ successMsg: '' })
                        // navigate("/userprofile")
                    }, 1500);
                }
            });
    }

    close() {
        if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
            navigate(this.props.location.state.prevPath);
        } else {
            navigate("/");
        }
    }

    render() {

        const { canSubmit, successMsg } = this.state;
        const { buttonLoader } = this.props;
        return (
            // <Layout layoutType="main">
            // <SEO title="Home" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />

            <div className="login-container">
                <span className="close-btn" onClick={() => { this.close() }}> <img src={close} alt="" /></span>
                <div className="left">
                    <div className="sign-in-bg" style={{ backgroundImage: `url(${loginImg})` }}>
                        <img onClick={() => { this.close() }} className="logo-white" src={Logo} alt="" />
                    </div>
                </div>
                <div className="right">


                    <Formsy ref="changePasswordForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                        <ErrrorHandel formName={'changePasswordForm'} self={this} />
                        <div className="sign-in-container">
                            <h2 className="title">Change Password</h2>
                            <p className="subtitle">Change your account password below.</p>
                            <div className="form-group error">
                                <MyInput
                                    name="oPassword"
                                    id="oPassword"
                                    type="password"
                                    placeholder="Old Password"
                                    value={''}
                                    className="form-control"
                                    isLabelRequired={true}
                                    required
                                />
                            </div>
                            <div className="form-group error">
                                <MyInput
                                    name="password"
                                    id="password"
                                    type="password"
                                    placeholder="New Password"
                                    value={''}
                                    className="form-control"
                                    isLabelRequired={true}
                                    validations={{
                                        minLength: 8
                                    }}
                                    validationError="Password must be 8 characters minimum."
                                    required
                                />
                            </div>
                            <div className="form-group error">
                                <MyInput
                                    name="cPassword"
                                    id="cPassword"
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={''}
                                    className="form-control"
                                    isLabelRequired={true}
                                    validations="equalsField:password"
                                    validationError="Password and password confimation do not match."
                                    required
                                />
                            </div>
                            <button className={!canSubmit ? 'btn disabled' : 'btn'} disabled={!canSubmit} type="submit">
                                <ButtonLoader isLoading={buttonLoader} label={'Submit'} />
                            </button>
                            <span className="change-password">{successMsg}</span>
                        </div>
                    </Formsy>
                    <div className="login-line">
                        <img src={bottomLine} alt="line" />
                    </div>
                </div>
            </div>

            // </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);